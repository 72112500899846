$pink:  #e5204d;
$white: rgb(237,237,237);
.specials-page {
  .menu-item {
    border: 1px dashed rgba(0,0,0,0.8);
  }
  .food-name {
    color: $pink;
    font-weight: 600;
    font-size: 1.3em;
  }
}
