@import '_vars.scss';
.whats-new {
  .header {
    max-width: $max-site-width;
    margin: 0 auto;
    font-size: 2em;
  }
  .link-wrapper {
    text-align: center;
    .order-online-button {
      margin: 0.5em;
      display: inline-block;
      font-size: 5vw;
    }
  }
}

.whats-new__content {
  .introduction {
    h1 {
      // max-width: 800px;
      margin: 0.5 auto;
      text-align: center;
      font-weight: 600;
      line-height: 1.5em;
      font-size: 3vw;
      padding: 0 0.3em;
      font-weight: 400;
      .made-in {
        font-weight: 200;
        font-size: 2.5vw;
      }
      @media screen and (max-width: 940px) {
        font-size: 1.3em;
        .made-in {
          font-size: 0.8em;
        }
      }
    }
    p {

      line-height: 1.3em;
      max-width: 550px;
      margin: 1em auto;
      padding: 0 0.3em
    }
    strong {
      font-weight: 900;
    }
  }
  .text-box-wrapper {
    display: flex;
    justify-content: center;
    margin-top: -8vw;
    // margin-left: 1em;
    margin-bottom: 1em;
  }
  .text-box {
    display: inline-block;
    background: $red;
    padding: 1em 1em;
    color: $white;
    h2 {
      text-transform: uppercase;
      font-size: 8vw;
      font-weight: 200;
    }
  }
  img {
    display: block;
    width: 100%;
    border-radius: 0.1em;
  }
  .full-spread {
    .sup {
      width: 50%;
      // margin: 0 auto;
      // margin-top: -1em;
    }
  }

}
